<!-- =========================================================================================
    File Name: TodoDataModal.vue
    Description: View Data component
    ----------------------------------------------------------------------------------------
    Item Name: Legalboard Services
      Author: Enkopmpass
    Author URL: http://www.enkompass.net
========================================================================================== -->


<template>
  <div id="data-model-container" class="vs-con-loading__container">

    <div id="data-list-list-view" v-if="itemData" class="data-list-container">
      <div slot="header" id="addnewcaseBtn" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <vx-tooltip text="Go Back" position="bottom" class="mr-4">
            <feather-icon 
              icon="ArrowLeftIcon" 
              class="cursor-pointer mr-2"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              @click.stop="goBack()" 
            />
          </vx-tooltip>
          <vs-button class="ml-2 mr-4 mb-4 flex items-center justify-center text-sm flex-grow-0" size="small" type="border"
                     @click.stop="addNewDataModel()"
          >
            Add New User
          </vs-button>
        </div>
      </div>
      <vs-table stripe search :data="itemData">
        <template slot="thead" v-if="menuItems[menuType]">
          <vs-th :sort-key="menu.sortKey" v-for="menu in menuItems[menuType]" :key="menu.label">{{menu.label}}</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody v-if="data">

          <vs-tr class="cursor-pointer" :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td>
              <p class="product-name font-medium">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p v-if="tr.encryptionKey" class="encryption-key">{{ tr.encryptionKey }}</p>
              <p v-if="tr.accountId" class="encryption-key">{{ tr.uuid }}</p>
            </vs-td>

            <vs-td>
              <p class="encryption-key">{{ formatDate(tr.createdOn) }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ formatDate(tr.createdOn) }}</p>
            </vs-td>

            <vs-td>
              <vx-tooltip text="Edit" position="left">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                              @click.stop="editItem(tr)" />
              </vx-tooltip>

              <vx-tooltip text="Delete" position="left">
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                              @click.stop="deleteItem(tr)" />
              </vx-tooltip>
            </vs-td>

          </vs-tr>
          </tbody>
        </template>

      </vs-table>
    </div>

    <div class="centerx">
      <todo-edit :displayPrompt="this.editPopup" @hideDisplayPrompt="hideEditPrompt" v-if="this.editPopup"
                 :taskId="this.currentItem" :activeType="this.menuType"></todo-edit>
      <todo-delete :displayDeletePrompt="deletePopup" @hideDisplayPrompt="hideDeletePrompt" :taskId="this.currentItem"
                   :activeType="this.menuType" v-if="this.deletePopup"></todo-delete>
      <todo-add-new :displayAddPrompt="addPopup" :taskId="this.taskLocal" @hideDisplayPrompt="hideAddNewPrompt"
                    v-if="this.addPopup"></todo-add-new>
    </div>

  </div>
</template>

<script>
  import moduleTodo from '@/store/todo/moduleTodo.js'
  import TodoDelete from './TodoDelete.vue'
  import TodoEdit from './TodoEdit.vue'
  import TodoAddNew from './TodoAddNew.vue'

  export default {
    components: {
      TodoDelete,
      TodoEdit,
      TodoAddNew
    },
    data () {
      return {
        itemData: [],
        currentItem:[],
        menuType: 'users',
        menuItems: {
          cases : [
            {label: "Case Name", sortKey: "name"},
            {label: "Case Id", sortKey: "caseId"},  
            {label: "Created", sortKey: "createdOn"}, 
            {label: "Updated", sortKey: "updatedOn"}, 
            {label: "Actions", sortKey: "name"}
          ],
          users : [
            {label: "User Name", sortKey: "name"}, 
            {label: "Email", sortKey: "uuid"}, 
            {label: "Created", sortKey: "createdOn"}, 
            {label: "Updated", sortKey: "updatedOn"},
            {label: "Actions", sortKey: "uuid"}
          ]
        },
        editPopup:false,
        addPopup:false,
        deletePopup:false,
        taskId: this.$route.params.accountId,
        taskLocal: {
          addType: 'user',
          uuid: this.$route.params.accountId
        },
      }
    },
    created() {
      if (!this.$store.hasModule("todo")) {
        this.$store.registerModule('todo', moduleTodo)
    }
    },
    mounted () {
      this.refreshData()
    },
    computed: {
      title () {
        return ('View Users')
      }
    },
    methods: {
      goBack() {
        this.$router.push("/");
      },
      refreshData() {
        this.$vs.loading({
          container: '#data-model-container',
          scale: 0.6
        })
        this.$store.dispatch('todo/fetchFirmData', {
          uuid: this.taskId,
          display_type: 'users'
        }).then(response => {
          this.itemData = response.items
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        }).catch(error => {
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        });
      },
      addNewDataModel() {
        this.addPopup = true;
      },
      hideDeletePrompt(data) {
        this.deletePopup = false;
        if (data && data.shouldRefresh) {
          this.refreshData()
        }
      },
      hideAddNewPrompt(data) {
        this.addPopup = false;
        if (data && data.shouldRefresh) {
          this.refreshData()
        }
      },
      hideEditPrompt(data){
        this.editPopup = false
        if (data && data.shouldRefresh) {
          this.refreshData()
        }
      },
      formatDate(value) {
        if (value && this.moment)
          return this.moment(value).format('DD/MM/YYYY')
        else return ''
      },
      deleteItem(item){
        this.currentItem = item
        this.currentItem.menuType = this.menuType
        this.deletePopup = true
      },
      editItem(item){
        this.currentItem = item
        this.currentItem.display_type = this.taskId.display_type
        this.editPopup = true
      },
    },
    beforeDestroy () {
      this.$store.unregisterModule('todo')
    },
  }
</script>
<style lang="scss">
  .con-vs-tooltip{
    width: 20px;
    margin: 0;
    padding: 0;
    display: inline;
    cursor: pointer;
  }

  #addnewcaseBtn{
    width: 50%;
    float: left;
    margin: auto 1rem;
  }

  #data-list-list-view .vs-con-table .vs-table--header {
    margin: 1rem;
    font-size: 12px;
  }

  .vs-table--search {
    max-width: 100%;
    width: 100%;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 0.65rem 2rem;
    width: 100%;
    margin-right: 18px;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input + i,
  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input:focus + i {
    left: 0.5rem;
  }
</style>
